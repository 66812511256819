




















import Vue from 'vue';
import { mapGetters } from 'vuex';
import Scaffold from '@/components/Mobile/Scaffold.vue';
import ClubCell from '@/club/ClubCell.vue';

export default Vue.extend({
  components: { Scaffold, ClubCell },
  computed: {
    ...mapGetters({
      clubs: 'user/getClubs',
      loading: 'isLoading',
    }),
  },
  async mounted() {
    this.loadClubs(true);
  },
  beforeDestroy() {
    this.$store.dispatch('user/clearClubs');
  },
  methods: {
    async loadClubs(showLoading: boolean) {
      const dispatch = this.$store.dispatch;
      if (showLoading) dispatch('toggleLoading', true);
      await dispatch('user/fetchClubs');
      if (showLoading) dispatch('toggleLoading', false);
    },
    sentinelVisibilitiChanged(isVisible: boolean) {
      if (!isVisible) return;
      this.loadClubs(false);
    },
  },
});
