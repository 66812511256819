



















































import Vue from 'vue';
import RideeUser from '@/dtos/user';

export default Vue.extend({
  props: {
    club: {
      type: RideeUser,
      required: true,
    },
  },
  computed: {
    toursCountText(): string | null {
      const count = this.club.statistics?.offeredToursCount || 0;
      if (!count) return null;
      const wording =
        count == 1
          ? this.$t('tour.offered.count.single')
          : this.$t('tour.offered.count.plural');
      return [count, wording].join(' ');
    },
    location(): string | undefined {
      return this.club.location?.description || undefined;
    },
  },
});
